//临时测试数据

const popularSearch:{id:number,content:string}[] = [
    {
        id:1,
        /*content:'Virtual Banks'*/
        content:'ZA Bank'
    },
    {
        id:2,
        /*content:'HK Banks'*/
        content:'weLab Bank'
    },
    {
        id:3,
        /*content:'Asia Banks'*/
        content:'Airstarbank'
    },
    {
        id:4,
        /*content:'Asia Banks'*/
        content:'Ant Bank'
    },
    {
        id:5,
        /*content:'Asia Banks'*/
        content:'Fusion Bank'
    },
    {
        id:6,
        /*content:'WEB3.0'*/
        content:'Livi Bank'
    },
    {
        id:7,
        /*content:'WEB3.0'*/
        content:'MOXBank'
    },
    {
        id:8,
        /*content:'AI-Powered Application'*/
        content:'PAO Bank'
    },
    // {
    //     id:6,
    //     content:'Payment Tech'
    // },
    // {
    //     id:7,
    //     content:'Dapp'
    // },
    // {
    //     id:8,
    //     content:'New App'
    // },
    // {
    //     id:8,
    //     content:'ESG'
    // },
    // {
    //     id:10,
    //     content:'Finance Plan'
    // },
    // {
    //     id:11,
    //     content:'Cryptocurrency'
    // },
]

export default popularSearch;