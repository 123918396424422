import React, {useEffect, useMemo, useState, FormEvent} from 'react';
import styles from './NewsAndReportsItems.module.css';
import LoadingSign from "../../components/LoadingSign";
import {PaginationBox} from "../../components/NewsTabAndPaginationBox";
import {NewsCard} from "../../components/NewsCard";
import {dateFormatter} from "../../utils/dateFormatter";
import { Link, useNavigate ,useSearchParams} from "react-router-dom";

/* const filterOptions = ['All', 'Product', 'Marketing', 'Financials', 'Staff', 'Tech', 'Web3.0'];
 */
interface KeyWordProps {
  height: number;
  content: string;
  fontSize: number;
  onClick?: () => void;
}

type NewsItem = {
  id: string;
  title: string;
  imgUrl: string;
  source: string;
  type: string;
  updateTime: string;
  tag: string;
  language: string;
};


export function NewsAndReportsItems() {
  const [selectedFilter, setSelectedFilter] = useState<string>('');
  const [newsData, setNewsData] = useState<NewsItem[]>([]);
  const [totalPage, setTotalPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [page, setPage] = useState(0);
  const [startDate, setStartDate] = useState<string>("0000-01-01"); //用作與當前時間相減，計算與目標時間過去了多少時間
  const [endDate, setEndDate] = useState<string>(new Date().toLocaleDateString('en-CA')); //用作與當前時間相減，計算與目標時間過去了多少時間

  const filterOptions = ['ALL', 'PRODUCT', 'MARKETING', 'FIN', 'STAFF', 'TECH', 'WEB3'];

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/news/all?page=${page}&size=${size}&newsType=${selectedFilter}&startDate=${startDate}&endDate=${endDate}`)
      .then((response) => response.json())
      .then((data) => {setNewsData(data.content); setTotalPage(data.totalPages-1);})
      .catch((error) => console.error(error));
  }, [page, selectedFilter, startDate, endDate]);

  const classNames = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"];

/*  const filteredNewsData = newsData.filter((news) => {
    if (selectedFilter === '') {
      return true;
    }
    return news.type === selectedFilter.toUpperCase();
  });*/


  const filteredNewsData = useMemo(() => {

  if (selectedFilter === 'All' || selectedFilter === '')  {
    console.log('newsData before filtering:', newsData);
    return newsData;
  } else{
    return newsData;
  }
}, [selectedFilter, newsData]);

  const handleFilterChange = (filter: string) => {
    setSelectedFilter(filter);
    setPage(0);
    
    //jumpToPage(1);
  };

  const handleSetDayTime = (value:number) =>{
    const today = new Date();
      const newValue = today.getMonth()-value;
      return (new Date(today.getFullYear(), today.getMonth(), today.getDate()-value).toLocaleDateString('en-CA'));
  }

  const handletimeFilter = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const today = new Date();
    setEndDate(new Date(today.getFullYear(), today.getMonth(), today.getDate()).toLocaleDateString('en-CA'));
    setStartDate(event.target.value);
  }

  const KeyWord = ({ fontSize = 2, content, onClick }: KeyWordProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isActived, setIsActived] = useState(false);

    const style = {
      height: "auto",
      width: "auto",
      fontSize: fontSize + "rem",
      borderRadius: "10rem",
      color: "var(--Red-Mid, #D92121)",
      fontFamily: '"Noto Sans HK"',
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "normal",
      letterSpacing: "-0.466px",
      backgroundColor: isActived ? "pink" : isHovered ? "#FEEFEF" : "white",  
      transition: "background-color 0.3s ease",
      padding: "1rem 1rem",
      marginRight: "0.2rem",
      cursor: "pointer",
    };


    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const handleActiveFilter = () => {
      if (content === selectedFilter) {
        setIsActived(true);
      }
    }

    useEffect(() => {
      handleActiveFilter();
    }, [selectedFilter]);


    return (
      <div
        className={styles.pinkbutton}
        style={style}
        onClick={onClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {content}
      </div>
    );
  };



  return (
    <div className={styles.filterContainer}>
      <div className={styles.header}>
        
        <p className={styles.news}>News</p>
        <p className={styles.inLocation}>Location:</p>
        <p className={styles.location}>Hong Kong</p>
        <div className={styles.divider}/>
      </div>
    <div className={styles.filterOptions_container}>
      <div className={styles.filterOptions}>
        <p className={styles.filterOptionsHeader}>Filter:</p>
        {filterOptions.map((filter, index) => (
          <KeyWord
            key={index}
            height={60}
            content={filter}
            fontSize={1.5}
            onClick={() => handleFilterChange(filter)}
          />
        ))}
        
        
      </div>
    </div>

        <div className={styles.select_container}>
          <p className={styles.filterOptionsHeader}>Time:</p>
            <div>
              <form>
                <select className={styles.select_box} onChange={handletimeFilter}>
                  <option value={handleSetDayTime(99999)}>all</option>
                  <option value={handleSetDayTime(365)}>within a year</option>
                  <option value={handleSetDayTime(182)}>within half a year</option>
                  <option value={handleSetDayTime(30)}>within a month</option>
                  <option value={handleSetDayTime(7)}>within a week</option>
                  <option value={handleSetDayTime(1)}>within a day</option>
                </select>
                {/* <div className={styles.icon_container}></div> */}
              </form>
            </div>
        </div>

      {newsData.length === 0 ? (
        <p style={{
          fontWeight: 700, 
          textAlign: 'center',
          fontSize: '20px', 
        }}>
          No Record Found.
        </p >
      ) : (
      <PaginationBox initPageSize={size+1} totalPages={totalPage} inPage={page}>
        {newsData.map((news, index) => {
          return (
            <div key={index % (size+1)} className={styles[classNames[index % (size+1)]]}>
              <NewsCard
                title={news.title}
                imgUrl={news.imgUrl}
                date={dateFormatter(news.updateTime)}
                tags={news.tag ? news.tag.split(",") : ["No Tags"]} 
                url={news.source}
                isVertical={index % (size+1) === 0}
              />
            </div>
          );
        })}

        <div className={styles.paginationBoxFooter}>
        <div className={styles.paginationBoxFooterButtons}>
          <div className={styles.indexBox}>
          {totalPage > 1 && (
            <>
            <button className={styles.arrowButton} onClick={() => setPage(0)}>&#171;</button>
            <button className={styles.arrowButton} onClick={() => {const prevPage = page - 1; setPage(prevPage < 0 ? 0 : prevPage)}}>&#8249;</button>
            </>
            )}
            {Array.from({ length: Math.min(totalPage,5) }, (_, index) =>  {
              if (page - 3 < 0) {
                return index;
              } else if (page + 3 > totalPage) {
                return totalPage - 4 + index;
              } else {
                return page - 3 + index + 1;
              }
              }).map(
              (item, index) => {
                return (
                  <div
                    className={
                      item === page ? styles.activeIndex : styles.index
                    }
                    onClick={() => setPage(item)}
                    key={item}
                  >
                    {item+1}
                  </div>
                );
              },
            )}
           {totalPage > 1 && (
            <>
            <button className={styles.arrowButton} onClick={() => {const nextPage = page + 1; setPage(nextPage > totalPage ? totalPage : nextPage)}}>&#8250;</button>
            <button className={styles.arrowButton} onClick={() => setPage(totalPage)}>&#187;</button>
            </>
            )}
          </div>
        </div>
      </div>
      </PaginationBox>
      )}
    </div>
  );
}