import React from "react";
//import { fakeCompanyDataGenerator } from "../test/fakeCompanyDataGenerator";

export interface CompanyData {
  id: string;
  name: string;
  companyLocations: {
    length: number;
    id: string;
    isoCode: string;
    region: string;
    address: string;
    isHQ: boolean;
    license: {
      id: string;
      name: string;
      time: string;
      link: string;
    }[];
  }[];
  foundedTime: string;
  founderName: string;
  bankSwift: string;
  bankCode: string;
  publicCompany: boolean;
  revenue: string;
  ceoname: string;
  employeeSize: string;
  website: string;
  status: string;
  web3Summary: string;
  techSummary: string;
  companyTags: string[];
  logoLink: string;
  numberOfUser: number;
  branding: string;
  motto: string;
  spotlight: string;
  digitalBankLicenseRentFrom: string;
  digitalInsuranceLicenseRentFrom: string;
  digitalBankLicense: boolean;
  rentDigitalBankLicense: boolean;
  digitalInsuranceLicense: boolean;
  rentDigitalInsuranceLicense: boolean;
  companyNews: {
    id: string;
    title: string;
    imgUrl?: string;
    source: string;
    type: string; // PRODUCT, FIN, STAFF, TECH, WEB3, COM, MARKETING
    updateTime: string;
    tag: string;
    language: string;
  }[];
  companyProducts: {
    id: string;
    name: string;
    description: string;
    productType: string; // ACCOUNT, PAYMENT, CARD, DEPOSIT, TRANSFER, LOAN, INVESTMENT, FE, INSURANCE, MORTGAGE, RA
    license: {
      id: string;
      name: string;
      time: string;
      link: string;
    }[];
    link: string;
    clientType: string[];
    innovative: boolean;
    innovation: string;
  }[];
  shareholders: {
    id: string;
    name: string;
    type: string; //INS, TECH, FIN, E
    introduction: string;
    link: string;
    shareholding: number;
    shareholderTags: { id: string; name: string }[];
  }[];
  staff: {
    id: string;
    name: string;
    title: string;
    department: string;
    imgUrl: string;
    staffType: string; // MANAGEMENT, PRODUCT, HR, Tech, FIN, DIRECTOR
    managementTeamTags: string[];
    director?: {
      id: string;
      directorType: string; // N, ED, NED, INED
      backgroundType: string; // Investor, Banker, Engineer, Entrepreneur, Consultant, HR, IT, Finance, Marketing, Accounting, Legal, Economics, Design, Business, PublicPolicy
    };
  }[];
  owners: {
    id: string;
    name: string;
    percent: number;
  }[];
  reports: {
    id: string;
    name: string;
    link: string;
    type: string;
    year: string;
  }[];
  finance: {
    id: string;
    breakEvenPoint: number;
    profitOrLoss: string;
    fundingRound: number;
    fundingAmount: string;
    totalInvestment: number;
    leadingInvestment: number;
    breakEven: number;
    reportCount: number;
    companyValuation: number;
    roi: number;
    marketCap: string;
    links: string;
    funds: {
      id: string;
      numberOfInvestors: number;
      fundingRound: string;
      moneyRaised: string;
      leadInvestor: string;
      announcedDate: string;
      link: string;
    }[];
    investments: {
      id: string;
      fundingRound: number;
      announcedDate: string;
      moneyRaised: string;
      organizationName: string;
      link: string;
    }[];
  };
  marketing: {
    id: string;
    companyStrategy: string;
    website: string;
    twitterId: string;
    linkedinId: string;
    facebookId: string;
    tiktokId: string;
    xhsId: string;
    youtubeId: string;
    wechatId: string;
    instagramId: string;
    twitter: string;
    linkedin: string;
    facebook: string;
    tiktok: string;
    xhs: string;
    youtube: string;
    wechat: string;
    instagram: string;
    googlePlayId: string;
    googlePlayLink: string;
    appStoreId: string;
    appStoreLink: string;
    numberOfCustomers: string;
    depositMarketShare: string;
    tam: string;
    customersGrowth: string;
    downloads: string;
    iosApp: {
      appId: string;
      appName: string;
      releaseDate: string;
      appGroup: string;
      price: number;
      lastUpdateDate: string;
      lastMonthDownloads: string;
    };
    googlePlayStar: number;
    appStoreStar: number;
    languageAvailable: string;
    categoryRank: number;
    campaigns: {
      id: string;
      name: string;
      description: string;
      updateDate: string;
      successful: boolean;
      campaignType: string; // RP, CA, PP, CR
      campaignUrls: {
        link: string;
        imgUrl: string;
      }[];
    }[];
  };
  web3:{
    id: string;
    buyAndsellCryptocurrency: boolean;
    buyAndSellCryptocurrencyMethod: string;
    buyAndsellNFT: boolean;
    buyAndSellNFTMethod: string;
    cryptoPayment: boolean;
    cryptoPaymentMethod: string;
    currencyExchangeServices: boolean;
    margin: boolean;
    derivatives: boolean;
    serviceFee0rPlanFee: number;
    customersupport: boolean;
    mobile0rOnline: boolean;
    planFee: string;
    exchangeSupport: boolean;
    defiWallet: boolean;
    dapps: string;
    nftissue: boolean;
    nftdescription: string;
    nftlable: string;
  };
}

//储存company level page的所有数据
//const mockState: CompanyData = fakeCompanyDataGenerator();

export const initState: CompanyData = {
  id: "",
  name: "",
  companyLocations: [{
    id: "",
    isoCode: "",
    region: "",
    address: "",
    isHQ: false,
    license: [],
    length: 0,
  }],
  foundedTime: "",
  founderName: "",
  bankSwift: "",
  bankCode: "",
  publicCompany: false,
  revenue: "",
  ceoname: "",
  employeeSize: "",
  website: "",
  status: "",
  web3Summary: "",
  techSummary: "",
  companyTags: [],
  logoLink: "",
  numberOfUser: 0,
  branding: "",
  motto: "",
  spotlight: "",
  companyNews: [],
  companyProducts: [],
  shareholders: [],
  staff: [],
  owners: [],
  reports: [],
  digitalBankLicenseRentFrom: "",
  digitalInsuranceLicenseRentFrom: "",
  digitalBankLicense: false,
  rentDigitalBankLicense: false,
  digitalInsuranceLicense: false,
  rentDigitalInsuranceLicense: false,
  finance: {
    id: "",
    breakEvenPoint: 0,
    profitOrLoss: "",
    fundingRound: 0,
    fundingAmount: "",
    totalInvestment: 0,
    leadingInvestment: 0,
    breakEven: 0,
    reportCount: 0,
    companyValuation: 0,
    roi: 0,
    marketCap: "",
    links: "",
    funds: [],
    investments: [],
  },
  marketing: {
    id: "",
    companyStrategy: "",
    website: "",
    twitterId: "",
    linkedinId: "",
    facebookId: "",
    tiktokId: "",
    xhsId: "",
    youtubeId: "",
    wechatId: "",
    instagramId: "",
    twitter: "",
    linkedin: "",
    facebook: "",
    tiktok: "",
    xhs: "",
    youtube: "",
    wechat: "",
    instagram: "",
    googlePlayId: "",
    googlePlayLink: "",
    appStoreId: "",
    appStoreLink: "",
    numberOfCustomers: "",
    depositMarketShare: "",
    tam: "",
    customersGrowth: "",
    downloads: "",
    iosApp: {
      appId: "",
      appName: "",
      releaseDate: "",
      appGroup: "",
      price: 0,
      lastUpdateDate: "",
      lastMonthDownloads: "",
    },
    googlePlayStar: 0,
    appStoreStar: 0,
    languageAvailable: "",
    categoryRank: 0,
    campaigns: [],
  },
  web3:{
    id: "",
    buyAndsellCryptocurrency: false,
    buyAndSellCryptocurrencyMethod: "",
    buyAndsellNFT: false,
    buyAndSellNFTMethod: "", 
    cryptoPayment: false,
    cryptoPaymentMethod: "",
    currencyExchangeServices: false,
    margin: false,
    derivatives: false,
    serviceFee0rPlanFee: 0,
    customersupport: false,
    mobile0rOnline: false,
    planFee: "",
    exchangeSupport: false,
    defiWallet: false,
    dapps: "",
    nftissue: false,
    nftdescription: "",
    nftlable: "",

  },


};
export const CompanyContext = React.createContext<CompanyData>(initState);
